import React, { useState } from "react";
import Contact from "../Contact/Contact";
import Footer from "../Footer/Footer";
import Landing from "../Landing/Landing";
import Navbar from "../NavBar/Navbar";
import NavbarMenu from "../NavbarMenu/NavbarMenu";
import SideBar from "../SideBar/SideBar";
import "./Content.css";

import basketball from "../../image/sports/basketball.jpeg";
import carrom from "../../image/sports/carrom.jpeg";
import hockey from "../../image/sports/hockey.jpeg";
import kabaddi from "../../image/sports/kabaddi.jpeg";
import table_tennis from "../../image/sports/Table Tennis.jpg";
import taekwondo from "../../image/sports/Taekwondo 2.jpeg";
import vollyball from "../../image/sports/vollyball.jpeg";

import badminton from "../../image/sports/badminton.jpg";
import chess from "../../image/sports/chess.jpg";
import yoga from "../../image/sports/yoga.jpg";
import football from "../../image/sports/Football.jpeg";
import athletics from "../../image/sports/Athletics.jpg";
import race from "../../image/sports/race.jpg";
import skipping from "../../image/sports/skipping.jpg";
import judo from "../../image/sports/Judo Karate.jpg";
import sicon from "../../image/newImg/sicon.png";
import { Card, Col, Row } from "react-bootstrap";

const SportsCard = (props) => {
  const { cover, sidebar_data, side_heading, side_sub_heading } = props;
  const [isScrolled, setIsScrolled] = useState(false);
  const [isShow, steIsShow] = useState(false);
  const handleScroll = (e) => {
    if (e.target.scrollTop > 0 && !isScrolled) {
      setIsScrolled(true);
    }
    if (e.target.scrollTop === 0) {
      setIsScrolled(false);
    }
  };
  const handleClick = () => {
    steIsShow(!isShow);
    console.log(isShow);
  };
  return (
    <div className="content_main" onScroll={handleScroll}>
      <Navbar isScrolled={isScrolled} handleClick={handleClick} />
      <NavbarMenu isShow={isShow} handleClick={handleClick} />
      <Landing video={false} cover={cover} />
      <div className="contentmain_cont">
        <div style={{ width: "min-content" }}>
          <SideBar
            sidebar_data={sidebar_data}
            side_heading={side_heading}
            side_sub_heading={side_sub_heading}
          />
          <Contact />
        </div>
        <div>
          <div className={`f-2 f-500 mb-3`} style={{ color: "#FF8C22ED" }}>
            <img src={sicon} style={{ width: "4rem", marginRight: "1rem" }} />
            Sports & Fitness
          </div>
          <div className="d-flex gap-2 mt-1">
            <div>
              <div className="color-grey f-500">
                Sports and Fitness improve the physical immunity and mental
                agility of students. Learning is induced, in a healthy and
                competitive environment and a good combination of outdoor games
                and indoor activities keeps them engaged and active. Inculcation
                of life skills like discipline, time management, ability to make
                decisions, teamwork, leadership, patience and learning from
                failure are developed only when children play sports regularly,
                and these skills are equally important when it comes to studies
                and management.
              </div>
            </div>
          </div>
          <div>
            <Col>
              <Row style={{ marginTop: "-1rem" }}>
                {/* <Col>
                  <Card
                    style={{
                      width: "15rem",
                      height: "15rem",
                      boxShadow: "10px 8px 4px rgba(0, 0, 0, 0.25)",
                      borderRadius: "10px",
                      margin: "auto",
                      marginTop: "2rem",
                      marginBottom: "2rem",
                    }}
                  >
                    <Card.Img
                      src={chess}
                      style={{ height: "12rem", marginLeft: "-0.5px" }}
                    />
                    <p style={{ textAlign: "center", marginTop: "1rem" }}>
                      <b>Chess</b>
                    </p>
                  </Card>
                </Col> */}
                <Col>
                  <Card
                    style={{
                      width: "15rem",
                      height: "15rem",
                      boxShadow: "10px 8px 4px rgba(0, 0, 0, 0.25)",
                      borderRadius: "10px",
                      margin: "auto",
                      marginTop: "2rem",
                      marginBottom: "2rem",
                    }}
                  >
                    <Card.Img
                      src={race}
                      style={{ height: "12rem", marginLeft: "-0.5px" }}
                    />
                    <p style={{ textAlign: "center", marginTop: "1rem" }}>
                      <b>Race</b>
                    </p>
                  </Card>
                </Col>
                <Col>
                  <Card
                    style={{
                      width: "15rem",
                      height: "15rem",
                      boxShadow: "10px 8px 4px rgba(0, 0, 0, 0.25)",
                      borderRadius: "10px",
                      margin: "auto",
                      marginTop: "2rem",
                      marginBottom: "2rem",
                    }}
                  >
                    <Card.Img
                      src={skipping}
                      style={{ height: "12rem", marginLeft: "-0.5px" }}
                    />
                    <p style={{ textAlign: "center", marginTop: "1rem" }}>
                      <b>Skipping</b>
                    </p>
                  </Card>
                </Col>
                <Col>
                  <Card
                    style={{
                      width: "15rem",
                      height: "15rem",
                      boxShadow: "10px 8px 4px rgba(0, 0, 0, 0.25)",
                      borderRadius: "10px",
                      margin: "auto",
                      marginTop: "2rem",
                      marginBottom: "2rem",
                    }}
                  >
                    <Card.Img
                      src={football}
                      style={{ height: "12rem", marginLeft: "-0.5px" }}
                    />
                    <p style={{ textAlign: "center", marginTop: "1rem" }}>
                      <b>Football</b>
                    </p>
                  </Card>
                </Col>
                {/* <Col>
                  <Card
                    style={{
                      width: "15rem",
                      height: "15rem",
                      boxShadow: "10px 8px 4px rgba(0, 0, 0, 0.25)",
                      borderRadius: "10px",
                      margin: "auto",
                      marginTop: "2rem",
                      marginBottom: "2rem",
                    }}
                  >
                    <Card.Img
                      src={judo}
                      style={{ height: "12rem", marginLeft: "-0.5px" }}
                    />
                    <p style={{ textAlign: "center", marginTop: "1rem" }}>
                      <b>Judo Karate</b>
                    </p>
                  </Card>
                </Col> */}
              </Row>
              <Row>
                {/* <Col>
                  <Card
                    style={{
                      width: "15rem",
                      height: "15rem",
                      boxShadow: "10px 8px 4px rgba(0, 0, 0, 0.25)",
                      borderRadius: "10px",
                      margin: "auto",
                      marginTop: "2rem",
                      marginBottom: "2rem",
                    }}
                  >
                    <Card.Img
                      style={{ height: "12rem", marginLeft: "-0.5px" }}
                      src={athletics}
                    />
                    <p style={{ textAlign: "center", marginTop: "1rem" }}>
                      <b>Athletics</b>
                    </p>
                  </Card>
                </Col> */}
                <Col>
                  <Card
                    style={{
                      width: "15rem",
                      height: "15rem",
                      boxShadow: "10px 8px 4px rgba(0, 0, 0, 0.25)",
                      borderRadius: "10px",
                      margin: "auto",
                      marginTop: "2rem",
                      marginBottom: "2rem",
                    }}
                  >
                    <Card.Img
                      style={{ height: "12rem", marginLeft: "-0.5px" }}
                      src={badminton}
                    />
                    <p style={{ textAlign: "center", marginTop: "1rem" }}>
                      <b>Badminton</b>
                    </p>
                  </Card>
                </Col>
                {/* <Col>
                  <Card
                    style={{
                      width: "15rem",
                      height: "15rem",
                      boxShadow: "10px 8px 4px rgba(0, 0, 0, 0.25)",
                      borderRadius: "10px",
                      margin: "auto",
                      marginTop: "2rem",
                      marginBottom: "2rem",
                    }}
                  >
                    <Card.Img
                      style={{ height: "12rem", marginLeft: "-0.5px" }}
                      src={badminton}
                    />
                    <p style={{ textAlign: "center", marginTop: "1rem" }}>
                      <b>Badminton</b>
                    </p>
                  </Card>
                </Col> */}
                <Col>
                  <Card
                    style={{
                      width: "15rem",
                      height: "15rem",
                      boxShadow: "10px 8px 4px rgba(0, 0, 0, 0.25)",
                      borderRadius: "10px",
                      margin: "auto",
                      margin: "auto",
                      marginTop: "2rem",
                      marginBottom: "2rem",
                    }}
                  >
                    <Card.Img
                      src={carrom}
                      style={{ height: "12rem", marginLeft: "-0.5px" }}
                    />
                    <p style={{ textAlign: "center", marginTop: "1rem" }}>
                      <b>Carrom Board</b>
                    </p>
                  </Card>
                </Col>

                {/* <Col>
                  <Card
                    style={{
                      width: "15rem",
                      height: "15rem",
                      boxShadow: "10px 8px 4px rgba(0, 0, 0, 0.25)",
                      borderRadius: "10px",
                      margin: "auto",
                      marginTop: "2rem",
                      marginBottom: "2rem",
                    }}
                  >
                    <Card.Img
                      src={basketball}
                      style={{ height: "12rem", marginLeft: "-0.5px" }}
                    />
                    <p style={{ textAlign: "center", marginTop: "1rem" }}>
                      <b>Basketball</b>
                    </p>
                  </Card>
                </Col> */}
                {/* <Col>
                  <Card
                    style={{
                      width: "15rem",
                      height: "15rem",
                      boxShadow: "10px 8px 4px rgba(0, 0, 0, 0.25)",
                      borderRadius: "10px",
                      margin: "auto",
                      marginTop: "2rem",
                      marginBottom: "2rem",
                    }}
                  >
                    <Card.Img
                      src={hockey}
                      style={{ height: "12rem", marginLeft: "-0.5px" }}
                    />
                    <p style={{ textAlign: "center", marginTop: "1rem" }}>
                      <b>Hokey</b>
                    </p>
                  </Card>
                </Col>
                 */}
                <Col>
                  <Card
                    style={{
                      width: "15rem",
                      height: "15rem",
                      boxShadow: "10px 8px 4px rgba(0, 0, 0, 0.25)",
                      borderRadius: "10px",
                      margin: "auto",
                      marginTop: "2rem",
                      marginBottom: "2rem",
                    }}
                  >
                    <Card.Img
                      src={yoga}
                      style={{ height: "12rem", marginLeft: "-0.5px" }}
                    />
                    <p style={{ textAlign: "center", marginTop: "1rem" }}>
                      <b>Yoga</b>
                    </p>
                  </Card>
                </Col>
              </Row>
              <Row style={{ marginTop: "-1rem" }}>
                {/* <Col>
                  <Card
                    style={{
                      width: "15rem",
                      height: "15rem",
                      boxShadow: "10px 8px 4px rgba(0, 0, 0, 0.25)",
                      borderRadius: "10px",
                      margin: "auto",
                      marginTop: "2rem",
                      marginBottom: "2rem",
                    }}
                  >
                    <Card.Img
                      src={table_tennis}
                      style={{ height: "12rem", marginLeft: "-0.5px" }}
                    />
                    <p style={{ textAlign: "center", marginTop: "1rem" }}>
                      <b>Table Tennis</b>
                    </p>
                  </Card>
                </Col> */}
                {/* <Col>
                  <Card
                    style={{
                      width: "15rem",
                      height: "15rem",
                      boxShadow: "10px 8px 4px rgba(0, 0, 0, 0.25)",
                      borderRadius: "10px",
                      margin: "auto",
                      marginTop: "2rem",
                      marginBottom: "2rem",
                    }}
                  >
                    <Card.Img
                      src={taekwondo}
                      style={{ height: "12rem", marginLeft: "-0.5px" }}
                    />
                    <p style={{ textAlign: "center", marginTop: "1rem" }}>
                      <b>Taekwondo</b>
                    </p>
                  </Card>
                </Col> */}
                {/* <Col>
                  <Card
                    style={{
                      width: "15rem",
                      height: "15rem",
                      boxShadow: "10px 8px 4px rgba(0, 0, 0, 0.25)",
                      borderRadius: "10px",
                      margin: "auto",
                      marginTop: "2rem",
                      marginBottom: "2rem",
                    }}
                  >
                    <Card.Img
                      src={vollyball}
                      style={{ height: "12rem", marginLeft: "-0.5px" }}
                    />
                    <p style={{ textAlign: "center", marginTop: "1rem" }}>
                      <b>Vollyball</b>
                    </p>
                  </Card>
                </Col> */}
                {/* <Row>
              <Col>

              </Col>
              <Col>
                  <Card
                    style={{
                      width: "15rem",
                      height: "15rem",
                      boxShadow: "10px 8px 4px rgba(0, 0, 0, 0.25)",
                      borderRadius: "10px",
                      margin: "auto",
                      marginTop: "2rem",
                      marginBottom: "2rem",
                    }}
                  >
                    <Card.Img
                      src={kabaddi}
                      style={{ height: "12rem", marginLeft: "-0.5px" }}
                    />
                    <p style={{ textAlign: "center", marginTop: "1rem" }}>
                      <b>Kabaddi</b>
                    </p>
                  </Card>
                </Col>
                  <Col></Col>
              </Row> */}
              </Row>
            </Col>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default SportsCard;
