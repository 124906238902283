import React, { useState } from "react";
import Contact from "../Contact/Contact";
import Footer from "../Footer/Footer";
import Landing from "../Landing/Landing";
import Navbar from "../NavBar/Navbar";
import NavbarMenu from "../NavbarMenu/NavbarMenu";
import SideBar from "../SideBar/SideBar";

import no from "../../image/parents/no.jpg";
import b from "../../image/parents/b.png";
import c from "../../image/parents/c.jpg";
import d from "../../image/parents/d.png";
import e from "../../image/parents/e.png";
import f from "../../image/parents/f.png";

import s1 from "../../image/students/s1.jpg";
import s2 from "../../image/students/s2.jpg";
import s3 from "../../image/students/s3.jpg";
import s4 from "../../image/students/s4.jpeg";
import s5 from "../../image/students/s5.jpeg";
import s6 from "../../image/students/s6.jpeg";
import paTopIcon from "../../image/newImg/pa.png";
import "./Content.css";

function Parents(props) {
  const { cover, sidebar_data, side_heading, side_sub_heading } = props;
  const [isScrolled, setIsScrolled] = useState(false);
  const [isShow, steIsShow] = useState(false);
  const handleScroll = (e) => {
    if (e.target.scrollTop > 0 && !isScrolled) {
      setIsScrolled(true);
    }
    if (e.target.scrollTop === 0) {
      setIsScrolled(false);
    }
  };
  const handleClick = () => {
    steIsShow(!isShow);
    console.log(isShow);
  };

  return (
    <div className="content_main" onScroll={handleScroll}>
      <Navbar isScrolled={isScrolled} handleClick={handleClick} />
      <NavbarMenu isShow={isShow} handleClick={handleClick} />
      <Landing video={false} cover={cover} />
      <div className="contentmain_cont">
        <div style={{ width: "min-content" }}>
          <SideBar
            sidebar_data={sidebar_data}
            side_heading={side_heading}
            side_sub_heading={side_sub_heading}
          />
          <Contact />
        </div>
        <div>
          {/* parents' voice */}
          <div className={`f-2 f-500 mb-3`} style={{ color: "#1FDC00ED" }}>
            <img
              src={paTopIcon}
              style={{ width: "4rem", marginRight: "1rem" }}
              alt=""
            />
            Parents' Voice
          </div>
          <div className={`parents_voice`} style={{ marginTop: "1.5rem" }}>
            {/* <img
              src={no}
              // src={b}
              style={{
                width: "10rem",
                height: "10rem",
                boxShadow: "10px 8px 4px rgba(0, 0, 0, 0.25)",
                border: "3px solid #1FDC00F5",
              }}
              alt=""
            /> */}
            {/* <div style={{ marginLeft: "12rem", marginTop: "-11rem" }}> */}
            <div style={{}}>
              <p style={{ marginTop: "1rem" }}>
                <b>Aniruddh Kanaujiya (KGII)</b>
              </p>
              <p style={{ marginTop: "1rem" }}>
                My children studies in Hermann Gmeiner School. School is very
                good. The behavior of teachers is also very good. They teaches
                very nicely. School conducts various activities like drawing,
                poem, speech, story competition etc. These activities improve my
                child to learn and grow.
              </p>
            </div>
          </div>
          <div style={{ marginTop: "1.5rem", display: "flex" }}>
            <div>
              <p style={{ marginTop: "1rem" }}>
                <b>Mohammad Arfan (KGII)</b>
              </p>
              <p style={{ marginTop: "1rem" }}>
                I am satisfied from school teachers. During Covid-19 situation
                online classes properly regulate by teachers. Teachers always
                encourage student for better performance.
              </p>
            </div>
            {/* <img
              src={no}
              // src={c}
              style={{
                width: "10rem",
                height: "10rem",
                border: "3px solid #1FDC00F5",
                boxShadow: "10px 8px 4px rgba(0, 0, 0, 0.25)",
              }}
              alt=""
            /> */}
          </div>
          <div style={{ marginTop: "2rem" }}>
            {/* <img
              src={no}
              // src={d}
              style={{
                width: "10rem",
                height: "10rem",
                border: "3px solid #1FDC00F5",
                boxShadow: "10px 8px 4px rgba(0, 0, 0, 0.25)",
              }}
              alt=""
            /> */}
            {/* <div style={{ marginLeft: "12rem", marginTop: "-11rem" }}> */}
            <div style={{}}>
              <p style={{ marginTop: "1rem" }}>
                <b>Ronak Nakwal (KGII)</b>
              </p>
              <p style={{ marginTop: "1rem" }}>
                School environment is good. Teachers are helpful. We are happy
                due to upgrading of school.
              </p>
            </div>
          </div>
          <div style={{ marginTop: "1.5rem", display: "flex" }}>
            <div>
              <p style={{ marginTop: "1rem" }}>
                <b>Goureesh Rathore Grade (IA)</b>
              </p>
              <p style={{ marginTop: "1rem" }}>
                We are Happy to say thank you all the teachers and management
                team of the school specially Deepti Madam for Nursery class
                teacher , Shahnaz Mam in class kg and Kg 1st and Shipra madam
                for 1st standard. All have very supporting nature. And in short
                I can say that my child future is in safe hands. Thank you to
                entire team of Herman Gmeinor School.
              </p>
            </div>
            {/* <img
              src={no}
              // src={e}
              style={{
                width: "10rem",
                height: "10rem",
                border: "3px solid #1FDC00F5",
                boxShadow: "10px 8px 4px rgba(0, 0, 0, 0.25)",
              }}
              alt=""
            /> */}
          </div>
          <div style={{ marginTop: "1.5rem", display: "flex" }}>
            {/* <img
              src={no}
              // src={f}
              style={{
                width: "10rem",
                height: "10rem",
                border: "3px solid #1FDC00F5",
                boxShadow: "10px 8px 4px rgba(0, 0, 0, 0.25)",
              }}
              alt=""
            /> */}
            {/* <div style={{ marginLeft: "2rem" }}> */}
            <div style={{}}>
              <p style={{ marginTop: "1rem" }}>
                <b>Yashika Grade (V)</b>
              </p>
              <p style={{ marginTop: "1rem" }}>
                Great school!!! My kids are very happy to be at this school.
                Teachers are very nice and helpfull. This school is home for our
                family.
              </p>
            </div>
          </div>
          {/*  */}
          <div className={`f-2 f-500 mt-5 `} style={{ color: "#1FDC00ED" }}>
            <img
              src={paTopIcon}
              style={{
                width: "4rem",
                marginRight: "1rem",
              }}
              alt=""
            />
            Students' Voice
          </div>
          <div className="parents_voice" style={{ marginTop: "1.5rem" }}>
            <div className="parentimage">
              <img
                src={s1}
                style={{
                  width: "10rem",
                  height: "10rem",
                  boxShadow: "10px 8px 4px rgba(0, 0, 0, 0.25)",
                  border: "3px solid #1FDC00F5",
                }}
                alt=""
              />
            </div>
            <div className="voice_text">
              <p style={{ marginTop: "1rem" }}>
                <b>Srajal Gupta</b>
              </p>
              <p style={{ marginTop: "1rem" }}>
                My school is my second home and is most popular organization. My
                teachers and guardians are very supporting and enlightening me
                wherever I stuck in taking any important decision. I am glad to
                have school like this.
              </p>
            </div>
          </div>
          <div className="parents_voicee">
            <div className="voicetextt">
              <p style={{ marginTop: "1rem" }}>
                <b>Aryan Prajapati</b>
              </p>
              <p style={{ marginTop: "1rem" }}>
                Our school provides all the basic and important facilities for
                the students which makes easier for the children's to
                concentrate in studies and all round development of students.
              </p>
            </div>
            <div className="parentimage">
              <img
                src={s2}
                style={{
                  width: "10rem",
                  height: "10rem",
                  border: "3px solid #1FDC00F5",
                  boxShadow: "10px 8px 4px rgba(0, 0, 0, 0.25)",
                }}
                alt=""
              />
            </div>
          </div>
          <div style={{ marginTop: "2rem" }}>
            <div className="parentimage">
              <img
                src={s3}
                style={{
                  width: "10rem",
                  height: "10rem",
                  border: "3px solid #1FDC00F5",
                  boxShadow: "10px 8px 4px rgba(0, 0, 0, 0.25)",
                }}
                alt=""
              />
            </div>
            <div className="voice_text">
              <p style={{ marginTop: "1rem" }}>
                <b>Janvi Patil</b>
              </p>
              <p style={{ marginTop: "1rem" }}>
                Hermann Gmeiner School, Bhopal...Great School, amazing teachers,
                beautiful campus these all things made our one of the best
                school in our locality. Every single child studying in this
                school gets special attention of Teachers. No student is left
                behind; the school does its best to provide a space for all
                students to thrive.
              </p>
            </div>
          </div>
          <div className="parents_voicee">
            <div className="voicetext">
              <p style={{ marginTop: "1rem" }}>
                <b>Aanchal Jaiswar</b>
              </p>
              <p style={{ marginTop: "1rem" }}>
                I am very happy with the school system and with the cooperative
                attitude of the Teachers for making me more confident to
                participate in all curricular and co-curricular activities. I am
                impressed with the co-curricular activities organized by the
                school. This has helped me to develop my confidence and to
                overcome stage fear. I am very satisfied. I always get ample
                opportunities to explore and progress in all aspects. I am very
                grateful to all teachers, coordinator and Principal.
              </p>
            </div>
            <div className="parentimage">
              <img
                src={s4}
                style={{
                  width: "10rem",
                  height: "10rem",
                  border: "3px solid #1FDC00F5",
                  boxShadow: "10px 8px 4px rgba(0, 0, 0, 0.25)",
                }}
                alt=""
              />
            </div>
          </div>
          <div
            className="parents_voice"
            style={{ marginTop: "2rem", marginBottom: "3rem" }}
          >
            <div className="parentimage">
              <img
                src={s5}
                style={{
                  width: "10rem",
                  height: "10rem",
                  border: "3px solid #1FDC00F5",
                  boxShadow: "10px 8px 4px rgba(0, 0, 0, 0.25)",
                }}
                alt=""
              />
            </div>
            <div className="voice_text">
              <p style={{ marginTop: "1rem" }}>
                <b>Akshat Singh</b>
              </p>
              <p style={{ marginTop: "1rem" }}>
                My School life is surely a learning experience. The confidence
                and motivation it gave me, I couldn’t get anywhere else. Most
                importantly it is the place where I recognized my uniqueness and
                individuality. My school life is no less than a treasure for me
                which gave me invaluable joys in life. The teachers and staff
                here are very supportive and caring.{" "}
              </p>
            </div>
          </div>
          <div
            className="parents_voicee"
            style={{ marginTop: "4rem", display: "flex" }}
          >
            <div className="voicetext">
              <p style={{ marginTop: "1rem" }}>
                <b>Vineet Thakur</b>
              </p>
              <p style={{ marginTop: "1rem" }}>
                My school teachers are very friendly and they teach very well so
                we understood very well. My school provides all basic facilities
                to the students. It is one of the best school in the locality.
              </p>
            </div>
            <div className="parentimage">
              <img
                src={s6}
                style={{
                  width: "10rem",
                  height: "10rem",
                  border: "3px solid #1FDC00F5",
                  boxShadow: "10px 8px 4px rgba(0, 0, 0, 0.25)",
                }}
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
export default Parents;
