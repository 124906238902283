import React, { useState } from "react";
import ReactPlayer from "react-player";

import campus from "../../video/Virtual Tour.mp4";
import { Link } from "react-router-dom";
import campus1 from "../../video/Admission Template bhopal.mp4";
import "./Landing.css";
import { IoCloseOutline } from "react-icons/io5";
import { BiLoaderAlt } from "react-icons/bi";
import { GiSpeaker, GiSpeakerOff } from "react-icons/gi";

function Landing({ video, cover }) {
  const [isMuted, setIsMuted] = useState(true);
  const [modal, setModal] = useState(false);
  const [videoLoading, setVideoLoading] = useState(true);

  const openModal = (e) => {
    console.log("BUtton is clicked");
    e.stopPropagation();
    setModal(!modal);
  };

  const spinner = () => {
    setVideoLoading(!videoLoading);
  };

  return video ? (
    <>
      <div className="position-relative">
        {/* <ReactPlayer
        url={campus}
        loop={true}
        muted={isMuted}
        playing={true}
        controls={false}
        width="100%"
        className="landing_video"
      /> */}
        {/* <GiSpeaker
        size={50}
        className="sound_icon  cursor"
        style={{ display: !isMuted ? null : "none" }}
        onClick={() => setIsMuted(true)}
      />
      <GiSpeakerOff
        size={50}
        className="sound_icon cursor"
        style={{ display: isMuted ? null : "none" }}
        onClick={() => setIsMuted(false)}
      /> */}
        <video
          src={campus1}
          autoPlay
          muted={isMuted}
          className="landing_video"
          loop={true}
          width="100%"
        />
        <Link to="/admissions" className="admission-button2">
          Admissions Open 2024-2025
        </Link>
      </div>
      <center>
        <button onClick={openModal} class="button-modal">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            class="bi bi-camera-reels-fill"
            viewBox="0 0 16 16"
          >
            <path d="M6 3a3 3 0 1 1-6 0 3 3 0 0 1 6 0" />
            <path d="M9 6a3 3 0 1 1 0-6 3 3 0 0 1 0 6" />
            <path d="M9 6h.5a2 2 0 0 1 1.983 1.738l3.11-1.382A1 1 0 0 1 16 7.269v7.462a1 1 0 0 1-1.406.913l-3.111-1.382A2 2 0 0 1 9.5 16H2a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2z" />
          </svg>
          <br />
          Virtual Tour
          {modal ? (
            <section className="modal__bg">
              <div className="modal__align">
                <div className="modal__content" modal={modal}>
                  <IoCloseOutline
                    className="modal__close"
                    arial-label="Close modal"
                    onClick={setModal}
                  />
                  <div className="modal__video-align">
                    {videoLoading ? (
                      <div className="modal__spinner">
                        <BiLoaderAlt
                          className="modal__spinner-style"
                          fadeIn="none"
                        />
                      </div>
                    ) : null}

                    <iframe
                      className="modal__video-style"
                      onLoad={spinner}
                      loading="lazy"
                      width="800"
                      height="500"
                      src="https://www.youtube.com/embed/Ouq-edZzDZg?si=dpuWXlaweRhe84Dp"
                      title="YouTube video player"
                      frameborder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                      referrerpolicy="strict-origin-when-cross-origin"
                      allowfullscreen
                    ></iframe>
                  </div>
                </div>
              </div>
            </section>
          ) : null}
        </button>
      </center>
    </>
  ) : (
    <div className="cover_img_cont">
      <img src={cover} alt="images" className="cover_img" />
    </div>
  );
}

export default Landing;
